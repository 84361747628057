<template>
  <v-app>
    <v-app-bar
      app
      color="#7246a4"
      dark
    >
      <div class="d-flex align-center">
       FlitsFlard
      </div>

      <v-spacer></v-spacer>

      <v-btn
        target="_blank"
        text
        @click="refreshData()"
      >
        <span class="mr-2">Nieuwe Flard</span>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <Header ref = "header" />
    </v-main>
  </v-app>
</template>
<style>
.v-main{
  background: rgb(255,255,255);
background: linear-gradient(160deg, rgba(255,255,255,1) 0%, rgba(233,255,232,0.8703627558479532) 100%);
}

</style>
<script>
import Header from './components/Header.vue';

export default {
  name: 'App',

  components: {
    Header
  },

  data: () => ({
    //
  }),
  methods:{
    refreshData(){
      // ref to module
      var header_ref = this.$refs.header;
      // you can access functions from ref.
      header_ref.getRandomQoute();
    }
  }
};
</script>
