<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/cyclist.png')"
          class="fiets"
          height="100"
          width="100"
        />
      </v-col>

      <v-col cols="12" class="mb-12 cont">
        <p class="display-3 quote">
          <span class="quot">&ldquo;</span>
          <span class="animateQuote">
            <span class="threedots">...</span> {{singleQoute}}<span class="threedots">...</span>
          </span>
        </p>
      </v-col>

      <v-col cols="12">
        <p><em>Wat is dit?</em></p>
        <div class="maintext">
        <h1 class="display-6 font-weight-bold mb-3">
          Flitsflard - een collectie flarden van flitsende wielrenners
        </h1>
        </div>
      </v-col>
      <v-col cols="12">
        <p><strong>Mijn kantoor ligt aan de straat. Een wielrenstraat. Honderden wielrenners komen langs per dag. En ze denken dat ze fluisteren.</strong></p>
        <p>Sommige uitspraken zijn te mooi, te bijzonder, te bevreemdend, te surrealistisch, te leuk of domweg aardig. Daarom deel ik ze graag.</p>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import quotes from '../assets/quotes.json';
  export default {
    name: 'Header',
    data() {
      return {
        quotes : quotes,
        singleQoute : '',
        interval:'',
      }
    },
    methods:{
      getRandomQoute: function(){
        this.singleQoute = this.quotes[Math.floor(Math.random()*quotes.length)];
      },
    },
    created(){
      var that = this;
      this.interval = setInterval(function() { that.getRandomQoute(); }, 5000);
    },
    mounted(){  
      this.getRandomQoute();
  }
  
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.maintext{
  background:#42b983;
  padding: 30px 20px 20px;
  color:white;
  border-radius: 3px;
}
.threedots{
  color:grey
}
.fiets{
  animation:ease-in-out infinite;
  animation-name:fietsbeweging;
  animation-duration:10s;
}
.cont{
  min-height: 240px;
}

@media screen and (max-width: 769px) {
    /* STYLES HERE */
    .cont{
      min-height: 375px;
    }
}
.animateQuote{
  animation: ease-in-out 10s infinite;
  animation-name: fadeInOut;
  line-height: 4.3rem;
}
@keyframes fadeInOut {
   0% {
    opacity: 0;
   }
  9%{
     opacity: 1;
   }
   42% {
     opacity: 1;
   }
  
   50%{
     opacity: 0;
   }
   58%{
     opacity: 1;
   }
   92% {
     opacity: 1;
   }
   100% {
     opacity: 0;
   }
  
}
@keyframes fietsbeweging {
   0% {
      left:-100px;
      opacity: 1;
      -webkit-transform:rotateY(180deg);
   }
   10% {
     opacity: 0.4;
   }
   15% {
     opacity: 1;
   }
   
   40% {
     opacity: 1;
   }
   43%{
     opacity: 0.4;
   }
   50% {
     opacity: 0;
      left: 100%;
      -webkit-transform:rotateY(180deg);
   }
   
   51% {
      -webkit-transform:rotateY(0deg);
   }
   58%{
     opacity: 0.4;
   }
   65%{
     opacity :1
   }
   90%{
     opacity: 1;
   }
   95%{
     opacity: 0.4;
   }
   99% {
      left:-100px;
      -webkit-transform:rotateY(0deg);
   }
   
   100% {
     opacity: 0;
      left:-100px;
      -webkit-transform:rotateY(180deg);
   }
}
@keyframes quotebeweging {
   0% {
    opacity: 0;
      -webkit-transform:translateY(30px);
   }
   10%{
     opacity: 0.4;
      -webkit-transform:translateY(30px);
   }
   30% {
     opacity: 1;
     -webkit-transform:translateY(0);
   }
  
   70%{
     opacity: 1;
     -webkit-transform:translateY(0);
   }
   90%{
     opacity: 0.4;
      -webkit-transform:translateY(30px);
   }
   100% {
     opacity: 0;
      -webkit-transform:translateY(30px);
   }
  
}
span.quot{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 2em;
    color:#42b983;
    position: absolute;
    opacity: 1;
    top: -25px;
    left: 3px;
    animation:ease-in-out infinite;
    animation-name:quotebeweging;
    animation-duration:10s;
  }
.quote{
  position: relative;
}
q{
  font-size: 3em;
}
h3 {
  margin: 40px 0 0;
}
ul {
  padding: 0;
}
li {
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
